<template>
  <div>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">Lixeira Instituições de Ensino</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Lixeira Instituições de Ensino
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-3 d-flex justify-content-md-end"></div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="block__classic table table__classic table-striped table-borderless table-hover text-center mb-4"
              >
                <thead>
                  <tr>
                    <th v-if="showClienteInfo" scope="col">Cliente</th>
                    <th scope="col" style="text-align: left">Nome</th>
                    <!-- <th scope="col" style="text-align: left">CNPJ</th> -->
                    <th scope="col" class="coluna-lixeira-restaurar" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td
                      v-if="showClienteInfo"
                      class="text-left"
                      v-html="item.cliente.nome"
                    />
                    <td class="text-left" v-html="item.nome" />
                    <!-- <td
                      class="text-left"
                      v-html="highlightMatches(item.cnpj)"
                    /> -->
                    <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="restore(item.id)"
                      >
                        Restaurar
                      </button>
                      <button
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(item.id)"
                      >
                        <i class="far fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination
                v-if="filteredRows.length"
                :offset="offset"
                :total="total"
                :limit="limit"
                @change-page="changePage"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import Swal from "sweetalert2";
import { constantes } from "@/constants";
import { checkUserHaveRole } from "@/tools";
import store from "@/store";
import Pagination from "@/components/pagination/Pagination";

export default {
  components: {
    Pagination,
    MainTopBar,
    MainLeftSideBar,
  },
  data() {
    return {
      offset: 0,
      total: 0,
      limit: 50,
      showLeftMenu: true,
      showClienteInfo: true,
      index: -1,
      item: {
        id: null,
        cliente: {},
        nome: "",
        razaoSocial: "",
        // cnpj: "",
        telefone: "",
        clienteSelecionado: null,
        responsavel: {
          nome: "",
          telefone: "",
          user: {
            email: "",
            senha: "",
          },
        },
      },
      items: [],
      clientes: [],
      filter: "",
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.nome.toLowerCase();
        return text.includes(searchTerm);
      });
    },
    formIsValid() {
      if (
        ((!this.item.clienteSelecionado ||
          this.item.clienteSelecionado == "") &&
          this.showClienteInfo) ||
        !this.item.nome ||
        this.item.nome == "" ||
        !this.item.razaoSocial ||
        this.item.razaoSocial == "" ||
        // !this.item.cnpj ||
        // this.item.cnpj == "" ||
        !this.item.telefone ||
        this.item.telefone == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });

    this.loadData();

    if (checkUserHaveRole([constantes.ROLE_SUPER_ADMIN])) {
      this.loadClientes();
    } else {
      this.showClienteInfo = false;
    }
  },
  methods: {
    restore(id) {
      // /api/professors/{id}/recovery
      this.$api
        .put("instituicao_ensinos/" + id + "/recovery", {})
        .then((d) => {
          console.log(d);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Instituição restaurado com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.items.splice(index, 1);
          setTimeout(function() {
            location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$root.$emit("Spinner::hide");
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro.",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    changePage(value) {
      this.offset = value;
      this.getPassengers();
    },
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    loadClientes() {
      this.$root.$emit("Spinner::show");

      this.$api
        .get("clientes")
        .then((response) => {
          this.clientes = response.data;

          this.$root.$emit("Spinner::hide");
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Você não tem acesso à esta ação neste módulo",
            showConfirmButton: false,
            timer: 1500,
          });


          this.$root.$emit("Spinner::hide");
        });
    },
    salvar() {
      this.saveServer();
    },
    deletar(index) {
      console.log(index);
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção dessa instituição?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$root.$emit("Spinner::show");

          this.$api
            .delete("instituicao_ensinos/" + index)
            .then(() => {
              this.$root.$emit("Spinner::hide");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Instituição removido com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              });
              setTimeout(function() {
                location.reload();
              }, 1000);
              // this.loadData();
              // window.location.reload()
            })
            .catch((error) => {
                console.log(error.response.data.detail)
              Swal.fire({
                position: "center",
                icon: "error",
                title: error.response.data.detail,
                showConfirmButton: false,
                timer: 1500,
              });
            });
          this.$root.$emit("Spinner::hide");
        }
      });
    },
    loadData() {
      this.$root.$emit("Spinner::show");

      this.$api
        .get("instituicao_ensinos/todas")
        .then((response) => {
          //  = response.data;
          response.data.forEach((res) => {
            if (res.trashAt != null) {
              this.items.push(res);
            }
          });
          this.$root.$emit("Spinner::hide");
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Você não tem acesso à esta ação neste módulo",
            showConfirmButton: false,
            timer: 1500,
          });

          this.$root.$emit("Spinner::hide");
        });
    },
    saveServer() {
      this.$root.$emit("Spinner::show");
      this.item.cliente = this.item.clienteSelecionado;

      if (!checkUserHaveRole([constantes.ROLE_SUPER_ADMIN])) {
        delete this.item.cliente;
      }

      console.log(this.item);

      if (this.index >= 0) {
        console.log(this.item);
        this.$api
          .put("instituicao_ensinos/edit/" + this.item.id, this.item)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Instituição alterada com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              setTimeout(function() {
                location.reload();
              }, 1000);
            });
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            if (error.response.status == 400) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.detail +
                  "</span>",
                showConfirmButton: false,
                timer: 3500,
              });
            } else if (error.response.status == 401) {
              store.dispatch("auth/ActionSignOut");
              window._Vue.$router.push({ name: "login" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.violations[0].message +
                  "</span>",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      } else {
        console.log(this.item);
        // this.item.cnpj = "50.274.631/0001-77";
        this.item.contato = "00";
        this.$api
          .post("instituicao_ensinos/add", this.item)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Instituição cadastrada com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.loadData();
            });
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            if (error.response.status == 400) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.detail +
                  "</span>",
                showConfirmButton: false,
                timer: 3500,
              });
            } else if (error.response.status == 401) {
              store.dispatch("auth/ActionSignOut");
              window._Vue.$router.push({ name: "login" });
            } else {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Instituição cadastrada com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.loadData();
              });
            }
          });
      }
    },
  },
};
</script>

<style>
.vm {
  max-width: 900px;
}
</style>
